// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import urls from  '@src/@core/auth/jwt/urls'
// ** Axios Imports
import axios from 'axios'
import { errorToast, sucessToast } from '../../../components/messageToast/messageToast'

const qs = require('qs')
export const getAllData = createAsyncThunk('appCustomers/getAllData', async (params) => {
  const instanceGet = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instanceGet.get(`/customers/customers/`, {params: {p:params.page, search:params.query}})
  return response
})

export const getAllCategories = createAsyncThunk('appCustomers/getAllCategories', async () => {
  const instanceGet = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instanceGet.get(`/customers/categories/get_allDetail/`)
  return response
})

export const getSubCategories = createAsyncThunk('appCustomers/getCategories', async id => {
  const instance = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  
  const response = await instance.get(`/customers/getSubcategoriesByClient/${id}`)
  return response.data
})

export const getCustomer = createAsyncThunk('appCustomers/getCustomer', async id => {
  const instance = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  
  const response = await instance.get(`/customers/customers/${id}/`)
  return response.data
})

export const getContact = createAsyncThunk('appCustomers/getContact', async id => {
  const instance = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })  
  const response = await instance.get(`/customers/contacts/${id}/`)
  return response.data
})

export const addContact = createAsyncThunk('appCustomers/addContact', (params, { dispatch }) => {
  const instance = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })  
  instance.post(`/customers/contacts/`, params.contact)
    .then(async resp => {
      await dispatch(getCustomer(params.idCustomer))
      sucessToast("Contacto se añadio correctamente")
      return resp.data
    })
    .catch(() => {
      errorToast("Contacto no se añadio correctamente")
    })
  
})

export const cleanContacts = createAsyncThunk('appCustomers/cleanContacts', () => {
  return "clean"
})

export const updateContact = createAsyncThunk('appCustomers/updateContact', (params, { dispatch }) => {
  const instance2 = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/x-www-form-urlencoded"}
  })
  instance2.put(`/customers/contacts/${params.contact.id}/`, qs.stringify(params.contact))
    .then(async resp => {
      await dispatch(getCustomer(params.idCustomer))
      sucessToast("Contacto se modifico correctamente")
      return resp.data
    })
    .catch(() => {
      errorToast("Contacto no se modifico correctamente")
    })
  
})


export const deleteContact = createAsyncThunk('appCustomers/deleteContact', (params, { dispatch }) => {
  const instance = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })  
  instance.delete(`/customers/contacts/${params.idContact}/`)
    .then(async resp => {
      await dispatch(getCustomer(params.idCustomer))
      sucessToast("Contacto se elimino correctamente")
      return resp.data
    })
    .catch(() => {
      errorToast("Contacto no se elimino correctamente")
    })
  
})

export const addCustomer = createAsyncThunk('appCustomers/addCustomer', async (params, { dispatch }) => {
  const instance = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  
  instance.post('/customers/customers/', params.customer)
    .then(async resp => {
      await dispatch(getAllData({page:1, query:""}))
      sucessToast("Cliente añadido correctamente")
      return resp.data
    })
    .catch((err) => {
      if (err?.response?.data?.error) {
        errorToast(err.response.data.msg)
      } else {
        errorToast("Cliente no fue creado correctamente")
      }
    })
  
})

export const updateCustomer = createAsyncThunk('appCustomers/updateCustomer', (params, { dispatch }) => {
  const instance2 = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/x-www-form-urlencoded"}
  })
  instance2.put(`/customers/customers/${params.customer.id}/`, qs.stringify(params.customer))
    .then(async resp => {
      await dispatch(getCustomer(params.customer.id))
      await dispatch(getSubCategories(params.customer.id))
      sucessToast("Cliente se modifico correctamente")
      return resp.data
    })
    .catch((err) => {
      errorToast(err?.response?.data?.msg ?? "Cliente no se modifico correctamente")
    })
})


export const updateCustomerWithoutToast = createAsyncThunk('appCustomers/updateCustomerWithoutToast', (params, { dispatch }) => {
  const instance2 = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/x-www-form-urlencoded"}
  })
  instance2.put(`/customers/customers/${params.customer.id}/`, qs.stringify(params.customer))
    .then(async resp => {
      await dispatch(getAllData(params.getData))
      return resp.data
    })
    .catch(() => {
      errorToast("Cliente no se modifico correctamente")
    })
})

export const deleteUser = createAsyncThunk('appCustomers/deleteUser', async (id, { dispatch}) => {
  await axios.delete(`/customers/customers/${id}/`)
  await dispatch(getAllData({page:1, query:""}))
  return id
})

export const setIsFetchingResults = createAsyncThunk('appCustomers/setIsFetchingResults', () => {
  return "fetching"
})

export const appCustomersSlice = createSlice({
  name: 'appCustomers',
  initialState: {
    allData: [],
    allCategories: [],
    selectedSubcategories : [],
    total: 1,
    selectedCustomer: null,
    selectedContact: null,
    isFetching: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(setIsFetchingResults.fulfilled, (state) => {
        state.isFetching = true
      })
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data.results
        state.total = action.payload.data.count
        state.isFetching = false
      })

      .addCase(getAllCategories.fulfilled, (state, action) => {
        state.allCategories = action.payload.data
        state.isFetching = false
      })

      .addCase(getSubCategories.fulfilled, (state, action) => {
        state.selectedSubcategories = action.payload
        state.isFetching = false
      })

      .addCase(getCustomer.fulfilled, (state, action) => {
        state.selectedCustomer = action.payload
      })

      .addCase(getContact.fulfilled, (state, action) => {
        state.selectedContact = action.payload
      })

      .addCase(cleanContacts.fulfilled, (state) => {
        state.allData = []
        state.total = 1
      })
  }
})

export default appCustomersSlice.reducer
